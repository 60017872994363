.top-prefecture__top {
    background-color: #6eb92b;
    padding-top: 30px;
    padding-bottom: 30px
}

.top-prefecture__btm {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: justify;
    word-break: break-all;
    -webkit-hyphens: auto;
    hyphens: auto;
    background-color: #f5f5f5;
    line-height: 1.7
}

.top-prefecture__btm--white {
    font-size: 12px;
    line-height: 1.6666;
    background-color: #fff
}